// Default color
$primary: rgb(59 130 246);
$dark: #151616;
$light: #fafafa;

// Alert color
$success: #b2f8b0;
$warning: #faeda6;
$danger: #f97c63;

// Borders color
$grey-light-border: #272727;

// Custom color
$blue-light-title: #3380FF;
$grey-light-text: #707070;