#header{
  background: transparent;
  position: fixed;
  transition: background 400ms ease-in-out;

  transform: translate(-50%, 0);
  left: 50%;
}
.nav-item{
  position: relative;
  &>.active{
    color: $primary;
  }
}

.navbar{
  display: flex;
  justify-content: space-between;
}

#header.scrolling{
  box-shadow: 0 2px 10px rgba($dark, .2);
  background: $light;
}

.burger-menu{
  display: none;
  z-index: 10;
  visibility: hidden;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 6px;
  cursor: pointer;
}

@media screen and (max-width: 842px) {
  .navbar {
    visibility: hidden;
    background: rgba($light, .95);
    opacity: 0;
    width: 100%;
    height: calc(100vh - max(185px));
    position: fixed;
    bottom: 0;

    left: -100%;
    margin-left: 0;
    transition: all 250ms ease-in-out;
    justify-content: flex-start;
    &>nav{
      margin-bottom: 55px;
      display: flex;
      align-items: center;
      justify-content: center;
      visibility: hidden;
      width: 100%;
      ul{
        display: flex;
        flex-direction: column;
        height: auto;
        gap: 10px;
        width: 90%;
        li{
          text-align: left;
          color: $dark;
          font-size: .945rem;
          width: 100%;
        }
      }
    }
  }
  .navbar.show {
    opacity: 1;
    visibility: visible;
    left: 0;
    color:$light;
    &>nav{
      visibility: visible;
      ul{
        align-items: flex-start;
      }
    }
  }

  .burger-menu {
    visibility: visible;
    display: flex;
  }
}

#user-card-infos {
  .user {
    &-email {
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}
