#about{
  &>div{
    .content{
      position: relative;
      &>:before{
        content: "";
        height: 1px;
        width: 100%;
        background: $dark;
        opacity: .08;

        position: absolute;
        left: 0;
        bottom: 0;
      }
    }
    .about-list{
      &>li{
        position: relative;
        &>:before{
          content: "";
          height: 1px;
          width: 100%;
          background: $dark;
          opacity: .08;

          position: absolute;
          left: 0;
          bottom: 0;
        }
        &:last-child:before{
          display: none
        }
      }
    }
  }
}