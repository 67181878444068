#news {
  & > div {
    position: relative;
    & > :before {
      content: "";
      height: 1px;
      width: 100%;
      background: $dark;
      position: absolute;
      bottom: 0;
      left: 0;
      opacity: .1;
    }
    .content{
      position: relative;
      & > :before {
        content: "";
        height: 1px;
        width: 100%;
        background: $dark;
        position: absolute;
        bottom: 0;
        left: 0;
        opacity: .1;
      }
    }
    h3{
      font-size: .945rem;
    }
  }
}
#new-section{
  min-height: 600px;
  position: relative;
  &>:before{
    content: "";
    height: 1px;
    width: 100%;
    background: $dark;
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: .2;
  }
  &>div{
    min-height: 600px;
    .wrapper{
      .content {
        h2 {
          position: relative;
        }
      }
      h3{
        font-size: .945rem;
      }
    }

    /** List on HomePage **/
    //ul {
    //  & > li.mobile {
    //    &:not(:first-of-type) {
    //      flex-flow: row wrap;
    //      .order-first { max-width: 33% }
    //      div { max-width: 63%; margin: 0 2% 0 0; }
    //      span { display: block; }
    //    }
    //  }
    //}
    }


}