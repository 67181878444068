.MuiDataGrid{
  &-columnHeader {
    border: 1px solid #000000;
    background-color: #c9cacd;
  }

  &-filterForm {
    flex-flow: row wrap;
    gap: 1rem;

    div:last-of-type { flex-grow: 1 }
  }
}

.projects {

  .MuiDataGrid {
    &-root {
      border: none;
      width: 100%;
      background: #FFFFFF 0 0 no-repeat padding-box;
      box-shadow: 0 0 6px #00000012;
      border-radius: 15px;
      opacity: 1
    }

    &-columnHeader { border: none; background: none; }

    &-columnHeaderTitle { font-weight: 700; font-size: 16px; }

    &-cell {
      font-size: 14px;

      &.custom-padding { padding: 14px 10px; }
    }
  }

}