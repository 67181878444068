.projects-further-actions {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  gap: 8px;
  opacity: .6;

  & > div {
    display: flex;
    width: 100%;
    align-items: center;

    span {
      margin: 0 0 0 4px;
      @media screen and (min-width: 1024px) { display: none; }
    }
  }

  @media screen and (min-width: 1024px) { flex-flow: row nowrap; }
}