#change_log {

  .title { margin: 0 auto 40px 0; }

  @for $index from 1 through 6 {
    h#{$index}{
      width: 100%;
      font-weight: 700;
    }
  }

  h1 {
    font-size: 30px;
    margin: 10px auto 20px;

    @media screen and (min-width: 900px) { font-size: 36px; }
  }
  h2 {
    font-size: 20px;
    margin: 10px auto;

    @media screen and (min-width: 900px) { font-size: 26px; }
  }
  hr { width: 100%; margin: 20px 0; }

  code { padding: 0 4px; font-size: 90%; color: #c7254e; background-color: #f9f2f4; }

  ul {
    padding: 20px 20px;
    width: 100%;

    & > li { list-style-type: square }

    @media screen and (min-width: 900px) { padding: 20px 40px; }
  }

  a {
    color: $primary;

    &:hover { text-decoration: underline }
  }

}
