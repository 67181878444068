#clock{
  transform:scale(2);
  position: relative;
  width: fit-content;

  .name {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 700;
    color: rgba(grey, .5);
  }
}

#countdownclock{
  position:absolute;
  margin:20px 0 0 -32px;
  top:50%;
  left:50%;
  transform: rotate(60deg);
}

#clock .clockline {
  fill: rgba(white, .06);
  //fill: url(#MyGradient);
  stroke:rgba(#3380FF, 1);
  stroke-width: 5;
  stroke-linecap: square;
  stroke-miterlimit: 100;
  //stroke-dasharray: 150 35;
  stroke-dasharray: 150 35;
  stroke-dashoffset: -1100;
  -webkit-animation: draw 8s .2s linear infinite;
  animation: draw 10s .2s linear infinite;
  fill-opacity: 1;
}

@keyframes draw {
  20%{stroke:#fff}
  40%{stroke:#ff0}
  60%{stroke:#CF2020}
  100%{stroke-dashoffset:0; stroke:#f00 }
}



/**
 * ==============================================
 * Bouncing Loader
 * ==============================================
 */
.bouncing-loader {
  display: flex;
  justify-content: center;
  position: relative;
  top: 2px;

  & > div {
    width: 8px;
    height: 8px;
    margin: 4px 2px 0;
    border-radius: 50%;
    background-color: #3380FF;
    opacity: 1;
    animation: bouncing-loader 0.6s infinite alternate;
  }

  & > div:nth-child(2) {
    animation-delay: 0.2s;
  }

  & > div:nth-child(3) {
    animation-delay: 0.4s;
  }
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translateY(-8px);
  }
}



/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
.dot-flashing {
  position: relative;
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: #3380FF;
  color: #3380FF;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;

  &::before, &::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;

    width: 8px;
    height: 8px;
    border-radius: 5px;
    background-color: #3380FF;
    color: #3380FF;
    animation: dot-flashing 1s infinite alternate;
  }

  &::before {
    left: -12px;
    animation-delay: 0s;
  }
  &::after {
    left: 12px;
    animation-delay: 1s;
  }
}

@keyframes dot-flashing {
  0% {
    background-color: #3380FF;
  }
  50%, 100% {
    background-color: rgba(#707070, 0.2);
  }
}

