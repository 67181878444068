#auth_menu {
  .dropdown {
    position: absolute;
    display: none;
    top: 40px;
    min-width: 180px;

    &.show { display: block; }
    &-submenu { left: 100%; top: auto; }

  }
}
