.card-network{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #f4f4f5;
  padding: 10px 4px 24px;
  min-height: 100px;
  position: relative;
  color: $grey-light-border;
  margin: 0;
  font-weight: 600;
  .default-tag {
    font-size: 10px;
    width: fit-content;
    color: white;
    padding: 1px 4px;
    position: absolute;
    bottom: 3px;
    right: 3px;
  }

}

.network-before{
  position: relative;
  display: block;
  &:after{
    isplay: block;
    content: "";
    height: 10px;
    width: 100%;
    max-width: 180px;
    background: #2D7EF5;
    position: absolute;
    bottom: -4px;
    left: 0;
    clip-path: polygon(0 4%, 100% 40%, 100% 100%, 1% 100%);
  }
}

.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-2.MuiGrid-grid-sm-4.MuiGrid-grid-md-4.css-19h8c {
  width: 500px !important;
}

.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-2.MuiGrid-grid-sm-4.MuiGrid-grid-md-4.css-eayafo-MuiGrid-root {
  width: 500px !important;
}

.MuiFormGroup-root.css-1h7anqn {
  width: 100% !important;
}
.uploadMainImage{
  position: absolute;
  top: 50%;
  left:50%;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 1024px) {
  .card-network{
    .css-103vid6-MuiTypography-root{
      font-size: .845rem !important;
    }
  }

  .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-2.MuiGrid-grid-sm-4.MuiGrid-grid-md-4.css-19h8c {
    width: 100% !important;
  }

  .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-2.MuiGrid-grid-sm-4.MuiGrid-grid-md-4.css-eayafo-MuiGrid-root {
    width: 100% !important;
  }

  .MuiFormGroup-root.css-1h7anqn {
    width: 100% !important;
  }
}