.catalogTitleInsta{
  position: relative;
  &:after{
    display: block;
    content: "";
    height: 10px;
    width: 100%;
    background: #f39c12;
    position: absolute;
    bottom: -4px;
    left: 0;
    clip-path: polygon(0 4%, 100% 40%, 100% 100%, 1% 100%);
  }
}