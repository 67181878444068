
#Catalog{
  background: transparent linear-gradient(180deg, #FFFFFF 0%, #ECEEF1 100%) 0% 0% no-repeat padding-box;
  &:before{
    content: "";
    height: 1px;
    width: 80%;
    background: $dark;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}
article#section-service {
  padding-bottom: 45px;
}
#catalog-section{
  min-height: 100vh;
  &>div{
    position: relative;
    min-height: 700px;
    padding-bottom: 95px;
    &>:before{
      content: "";
      height: 1px;
      width: 100%;
      background: $dark;
      opacity: .09;

      position: absolute;
      left: 0;
      bottom: 25px;
    }
    .img-box{
      height: 750px;
      figure{
        height: 750px;
        overflow: hidden;
        img{
          height: 100%;
          width: 100%;
          border-radius: 5px;
          object-fit: cover;
        }
      }
    }
  }
}
.css-dmmspl-MuiFormGroup-root {
  width: 100%;
}
.card-network{
  &>label{
    &>span{
      &:last-child{
        width: 100%;
        text-align: center;
        font-weight: 600;
        color: $dark;
      }
    }
  }
}


.catalogTitle{
  position: relative;
  &:before, &:after{
    content: "";
    position: absolute;
    left: 0;

    background: #f39c12;
  }
  &:before{
    animation: slideIn 1s ease-in-out;
    bottom:0;
    height: 6px;
    width: 100px;
  }
  &:after{
    animation: slideIn2 1s ease-in-out;
    height: 3px;
    bottom: 1px;
    width: 170px;
  }
}
.catalogTitleSpan{
  position: relative;
  &:after{
    display: block;
    content: "";
    height: 10px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    clip-path: polygon(0 4%, 100% 40%, 100% 100%, 1% 100%);
  }
}
.servicesSpan:after{
    background: #3380FF;
}
.installationsSpan:after{
  background-color:#FB923CFF;
}
