#hero{
  &:before{
    content: "";
    height: 1px;
    width: 80%;
    background: $dark;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    opacity: .1;
  }

  .wrapper{
    min-height: 600px;
  }

  .list-feature{
    &>li{
      position: relative;
    }
  }
}

.w-full.lg\:w-1\/2.h-full.my-auto {
  margin-top: 0;
}

.linearGradient400{
    background-image: linear-gradient(#ffffff, transparent);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    z-index: 10;
}

.borderDesign{
  position: relative;
  &::before{
    content:"";
    height: 4px;
    width: 100%;
    background: $primary;
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 50px;

  }
}

