@import "../../../../assets/scss/Variables";

@mixin missing-fields-border {
  border: 2px solid #FE5353;
  background: rgba(#FE5353, .02)
}

#charter, #charter_success {
  background: transparent linear-gradient(180deg, #FFFFFF 0%, #ffffff 100%) 0% 0% no-repeat padding-box;

  &_success {
    .success {
      &-box {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 400px;
        min-height: 56vh;
        background-color: rgba(255, 255, 255, 0.4);
        border-radius: 10px;
        padding: 8%;
        box-shadow: 0 4px 14px 4px rgba(0, 0, 0, 0.06);
        margin: auto;
        text-align: center;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          border: 1px solid rgba(39, 39, 39, 0.08);
          border-radius: 7px;
          width: 98%;
          height: 98%;
          top: 1%;
        }

        @media screen and (min-width: 900px) { padding: 3%; }
      }

      &-title {
        margin: 10% 0 5%;

        span:after { background: #5bb543; animation: expandWidth 1s ease; }

        @keyframes expandWidth {
          0% { width: 0; }
          100% { width: 100%; }
        }

        @media screen and (min-width: 900px) { margin: 0 0 3%; }
      }

      &-text {
        margin: 3% 0;

        p { margin: 5% 0; }

        @media screen and (min-width: 900px) {
          max-width: 80%;
        }
      }
    }
  }
}