#resource-infos {
  #reusableform.resource-infos {
    background: none;
  }
  #reusableform.resource-infos .container {
    justify-content: inherit;
  }
  #reusableform.resource-infos .wrapper {
    margin: 0;
    padding: 0;
  }
  #reusableform.resource-infos .stepper {
    padding: 0;

    @media screen and (max-width: 1200px) {
      margin: 0;
    }
  }
}