.step-status {
  .chip {
    padding: 4px 10px;
    margin: 4px 0;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 700;
    color: #FFFFFF;
    width: fit-content;
  }
}
