@import "../../../../assets/scss/Variables";

$draft-color : #e6c229;
$incoming-color : #3380FF;
$validated-color : #4caf50;
$deleted-color : #FE5353;

#ppa_activity {

  & > div.container {
    align-items: normal
  }

  & + button.scroll-top { display: none; }

  h3 { color: rgba($grey-light-text, .4) }

  .draft  {
    color: $draft-color;
    h3 { color: $draft-color }
  }
  .incoming {
    color: $incoming-color;
    h3 { color: $incoming-color }
  }
  .validated {
    color: $validated-color;
    h3 { color: $validated-color }
  }
  .deleted {
    color: $deleted-color;
    h3 { color: $deleted-color }
  }

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    color: rgba($grey-light-text, .4);

    svg {
      font-size: 32px;

      @media screen and (min-width: 900px) { font-size: 40px; }

      &.bounce {
        animation: bounce 2s infinite linear; /* Animation */
      }
    }

    @keyframes bounce {
      0% {transform: scale(100%)}
      50% {transform: scale(120%)}
      100% {transform: scale(100%)}
    }

    &.draft { color: rgba($draft-color, 1); }
    &.incoming { color: rgba($incoming-color, 1); }
    &.validated { color: rgba($validated-color, 1); }
    &.deleted { color: rgba($deleted-color, 1); }
  }


  #clock {
    transform:none;
    position: relative;
    width: fit-content;

    .clockline {
      fill: rgba(white, .06);
      stroke:rgba($grey-light-text, .4);
      stroke-width: 6;
      stroke-linecap: square;
      stroke-miterlimit: 100;
      stroke-dasharray: 218 20;
      stroke-dashoffset: -1100;
      -webkit-animation: none;
      animation: none;
      fill-opacity: 1;

      &.draft {
        //fill: rgba(white, 1);
        stroke:rgba($draft-color, 1);
      }
      &.incoming { stroke:rgba($incoming-color, 1); }
      &.validated { stroke:rgba($validated-color, 1); }
      &.deleted { stroke:rgba($deleted-color, 1); }
    }
  }

  .connector {
    position: relative;
    overflow: hidden;
    width: 8px;

    &:before {
      content: '';
      position: absolute;
      width: 100px;
      height: 100%;
      background-image: linear-gradient(
                      120deg,
                      rgba(255,255,255, 0) 30%,
                      rgba(255,255,255, .6),
                      rgba(255,255,255, 0) 70%
      );
      top: -100px;
      left: -50px;
      animation: shine 2.6s infinite linear; /* Animation */
    }
  }

  @keyframes shine {
    0% {top: -100px}
    20% {top: 100%}
    100% {top: 100%}
  }


  .date-box {
    text-align: left;

    div { font-weight: 700; }

    @media screen and (min-width: 900px) {
      div {
        padding: 12px;
        font-size: 20px;
        color: #FFFFFF;
        margin: 10px 0 0;


        &.draft { background-color: rgba($draft-color, 1); }
        &.incoming { background-color: rgba($incoming-color, 1); }
        &.validated { background-color: rgba($validated-color, 1); }
        &.deleted { background-color: rgba($deleted-color, 1); }
      }
    }
  }

  .link-box {
    justify-content: center;
    width: 90vw;
    position: fixed;
    bottom: 20px;
    left: 5%;
    transform: translateX(50%);
    z-index: 2;
    background: white;
    border-radius: 10px;
    padding: 12px;
    box-shadow: 4px 4px 8px rgba($grey-light-text, .1);

    @media screen and (min-width: 900px) {
      justify-content: flex-start;
      width: fit-content;
      position: unset;
      margin: 20px 0 0;
      padding: 16px;
    }
  }
}






