.projects-ppa-list {
  .heading {
    button {
      border-radius: 8px;
      opacity: 1;
      padding: 8px 16px;
      width: fit-content;
      align-self: end;

      &:hover { box-shadow: none; }
    }
  }
}