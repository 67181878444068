.brand{
  height: 45px;
  width: 45px;
  figure{
    height: 45px;
    width: 45px;
    img{
      height: 100%;
      width: 100%;

      object-fit: contain;
    }
  }
}