@import "../../../../assets/scss/_Variables.scss";
.node__leaf > circle {
  fill: $primary;
}

.node__branch > circle {
  fill: $grey-light-text;
}

.node__root > circle {
  fill: $primary;
}